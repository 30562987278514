import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { useField } from 'react-final-form';
import { useAppdata, useTranslation } from '../../lib/hooks.context';
import { SpecialFormField as Props, Site } from '../../types';

const FormField = dynamic(() => import('./FormField'));

const defaultName = 'yhdistys';

const moveMainSiteTop = (sites: Site[]) => {
  const newSites = [...sites];
  const index = newSites.findIndex((site) => site.name === '');
  return index !== -1 ? newSites.splice(index, 1).concat(newSites) : sites;
};

const SelectForestAssociation = ({
  name = defaultName,
  label,
  required,
  fields,
  field_type,
  ...rest
}: Partial<Omit<Props, 'type'>>) => {
  const { sites, subsite: site } = useAppdata();
  const {
    input: { onChange, value },
  } = useField(name);
  const t = useTranslation();

  useEffect(() => {
    if (site && !value) {
      onChange(site);
    }
  }, [site, value, onChange]);
  if (site) {
    return null;
  }

  const sortedSites = [...sites]?.sort((a, b) =>
    a.blogname.localeCompare(b.blogname, 'fi'),
  );

  const siteToOption = ({
    name: optionName,
    blogname: optionLabel,
    tags,
  }: Site) => ({
    value: optionName || 'main',
    label: !optionName ? t('form_site_not_known') : optionLabel,
    tags,
  });

  return (
    <FormField
      {...rest}
      type="select"
      label={label || t('brand_name_singular')}
      name={name}
      required={required || true}
      options={moveMainSiteTop(sortedSites)?.map(siteToOption)}
    />
  );
};

export default SelectForestAssociation;
